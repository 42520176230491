.h2 {
  @apply px-3 py-2 shadow-md text-white font-bold text-2xl rounded-sm bg-indigo-950 translate-y-3 z-10;
}

.formulario {
  @apply flex flex-col gap-4 w-full bg-white border border-cyan-600 px-5 pb-3 pt-10 rounded-sm shadow-md;
}

.formulario > div {
  @apply flex flex-col;
}

.formulario label {
  @apply text-sm font-bold;
}

.userTable{
  @apply grid gap-3 p-3 pt-10 bg-sky-50 max-h-72 overflow-y-scroll rounded-sm shadow w-full relative border border-cyan-600;
}

.userRow {
  @apply grid grid-cols-5 justify-around items-center gap-2 border-b border-slate-400 py-1 px-2 text-white rounded-sm;
}

.userRow:nth-child(odd) {
  @apply bg-cyan-900;
}

.userRow:nth-child(even) {
  @apply bg-cyan-700;
}
/******************************************************************/
