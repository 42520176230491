
.timeParent{
    @apply flex items-end gap-2
}

.descansosParent{
    @apply bg-stone-100 flex-row justify-start items-stretch gap-8 overflow-x-scroll
}
.descansoInfo{
    @apply w-4/12 flex flex-col py-2 px-4 bg-stone-50 relative shadow-md gap-4 flex-shrink-0
}

.eliminarDescanso{
    @apply text-sm text-gray-500 hover:text-red-500 cursor-pointer absolute -top-2 right-4
}

fieldset{
    @apply w-full flex rounded-sm border border-stone-500 bg-stone-50 p-4 shadow-sm
}

legend{
    @apply text-2xl text-red-500
}