@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito+Sans:opsz@6..12&family=Raleway&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    height: 100%;
    font-size: calc(14px + 0.38vw);
  }

  * {
    box-sizing: border-box;
  }
  body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    @apply font-nunitosans text-slate-800;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  button {
    padding: 0.25rem 0.5rem;
    transition: 0.3s all;
  }

  button:disabled {
    cursor: not-allowed;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    width: 3rem;
    text-align: right;
    padding-right: 0.125rem;
  }

  input {
    padding-left: 0.25rem;
    border-bottom: 1px solid rgb(148 163 184 / var(--tw-border-opacity));
    background-color: transparent;
    caret-color: #475569;
  }

  input:focus-visible {
    outline: none;
  }
  input:focus-within {
    border-color: rgb(16 185 129 / var(--tw-border-opacity));
  }

  select {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
  }
  textarea {
    padding: 0.25rem;
    border: 1px solid rgb(107, 107, 107);
    border-radius: 2px;
    height: 70px;
  }
  div {
    background-color: transparent;
  }
}

@layer components {
  .oval {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(71, 71, 71, 0.5);
    z-index: 20;
  }

  .flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav-general {
    box-shadow: inset 0px -1px 0px 0px #c4230e;
    background: url("https://slidebazaar.com/wp-content/uploads/2023/05/Free-Powerpoint-Backgrounds-Template-Blue-and-White.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    display: grid;
    grid-template-columns: 15% 70% 15%;
    padding: 0.5rem 1.25rem 1rem 1.25rem;
    align-items: end;
    justify-items: center;
  }

  .admin-option {
    @apply relative col-span-1 border text-center h-[25vh] flex flex-col items-center justify-center rounded-sm hover:scale-105 hover:bg-slate-50 hover:border-red-200 transition-all cursor-pointer;
  }

  .calendar {
    border-radius: 0.125rem /* 2px */;
    color: white;
    width: 100%;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: auto auto;
    align-items: center;
    box-shadow: 1px -1px 3px 0px rgb(165, 165, 165);
    background-color: rgb(244 244 245);
  }

  .calendar > div {
    height: 100%;
    width: 100%;
    justify-content: center;
  }

  .book-info,
  .book-info > div {
    display: grid;
    gap: 1px;
  }

  .mi-boton {
    color: white;
    display: block;
    border-radius: 0.25rem;
    font-weight: bold;
  }

  .guardar {
    @apply bg-emerald-600 hover:bg-emerald-700;
  }

  .mi-boton-2 {
    color: rgb(30 41 59);
    border-bottom: 1px solid rgb(148 163 184);
    background-color: white;
  }

  .admin-option-info::after {
    content: "Debes iniciar el curso para acceder a las reservas";
    padding: 0.5rem;
    background-color: #475569;
    color: white;
    display: none;
    width: 100%;
    position: absolute;
  }

  .admin-option:hover::after {
    display: block;
  }

  .cell {
    padding: 0.5rem;
    min-height: 3rem;
    height: auto;
    background-color: rgb(245 245 244 / var(--tw-bg-opacity));
    border-radius: 1px;
    text-align: center;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .empty-cell:hover::after {
    display: block;
  }

  .empty-cell::after {
    content: "Haz click para crear una reserva";
    background-color: rgba(24, 24, 24, 0.618);
    padding: 0.125rem;
    font-size: 0.8rem;
    border-radius: 0.125rem;
    display: none;
    position: absolute;
  }

  .success-message,
  .error-message {
    animation: message-animation 5s linear forwards;
    left: 50%;
    translate: -50%;
    position: fixed;
    top: 0.75rem;
    width: fit-content;
    height: 4rem;
    border-radius: 0.25rem;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    z-index: 50;
    padding: 0.5rem;

    @apply shadow;
  }
  .error-message {
    @apply bg-orange-500/95;
  }

  .success-message {
    @apply bg-emerald-500/95;
  }
  .search-bar::placeholder {
    color: rgb(32, 32, 32);
  }
  /****************************************/
  .switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 90%;
    width: 40%;
    left: 0.125rem;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #f3cd21;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    /*  -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);  */
    left: 60%;
    transform: translateY(-50%) translateX(-0.125rem);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

@keyframes message-animation {
  0% {
    opacity: 0;
    transform: scale(0%);
  }
  10% {
    opacity: 1;
    transform: scale(100%);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
