.formulario {
  position: absolute;
  inset: 0px;
  height: 100%;
  z-index: 10;
  background-color: rgb(51 65 85 / 0.7);
  backdrop-filter: blur(5px);
  border-radius: 0.125rem;
  color: rgb(30 41 59);
  animation: show-formulario 0.3s forwards ease-out;
}

.hideFormulario {
  animation: hide-formulario 0.2s forwards ease-out;
}

.formulario > div {
  width: 50%;
  background-color: white;
  border-radius: 0.125rem;
  position: relative;
}

.header {
  padding: 0.5rem;
  font-size: 1rem /* 16px */;
  line-height: 1.5rem;
  border-bottom: 1px solid rgb(30 41 59);
  border-top-left-radius: 0.25rem /* 4px */;
  border-top-right-radius: 0.25rem /* 4px */;
}

.formulario form {
  @apply flex flex-col gap-3 p-3 w-full;
}

.formulario input,
.formulario select {
  cursor: pointer;
}

@keyframes show-formulario {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes hide-formulario {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
