@tailwind components;

@layer components {
  .content {
    background-image: url("https://newmibridges.michigan.gov/resource/1699095958000/ISD_Icons/landing-page/group-3.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .inputLabel {
    @apply absolute z-[1] inset-x-0 transition-all text-gray-500;
    top: -50%;
    left: 1%;
    font-size: 0.7rem;
  }

  .pestañaDefault {
    @apply relative z-20 px-3 py-2 transition-all cursor-pointer text-white font-bold text-[1em] rounded-[2px];
  }

  .loginForm {
    @apply w-3/4 h-auto rounded relative bg-stone-50 shadow-2xl pt-20 pb-5;
  }
  .loginForm::placeholder {
    color: transparent !important;
  }
}

form {
  @apply gap-8 p-2 w-3/4 h-fit text-slate-700;
}

input {
  @apply border-b-[1px] border-slate-300;
}
